<script setup>
import { ref, onMounted } from 'vue';
import { getOrCreateDataObject } from 'o365-dataobject';
import { OScopeItemsLookup } from 'o365-system-lookups';
import NewActivityModal from 'sja.vue.libraries.components.CreateSJAActivityModal.vue'
import { isMobile } from 'o365.GlobalState.ts';

const props = defineProps({
    sjaID: Number,
    readOnly: Boolean
});

let createActivityModal = ref();

const dsActivities = getOrCreateDataObject({
    id: `dsActivities_${props.sjaID}`,
    uniqueTable: 'atbv_SJA_SJAActivities',
    maxRecords: -1,
    viewName: 'aviw_SJA_SJAActivities',
    allowUpdate: true,
    allowInsert: true,
    allowDelete: true,
    disableLayouts: true,
    fields: [
        { name: 'PrimKey', },
        { name: 'ID', },
        { name: 'SJA_ID', },
        { name: 'Activity_ID', },
        { name: 'Activity', },
        { name: 'Completed', },
        { name: 'OrgUnit_ID', },
        { name: 'PlannedFinish', },
        { name: 'ActivityType', },
        { name: 'OrgUnit', },
        { name: 'Link', }
    ],
    whereClause: "SJA_ID = " + props.sjaID
});

// Custom activities lookup for when imported component wasn't working
// const dsActivitiesLkp = getOrCreateDataObject({
    // id: `dsActivitiesLkp_${props.sjaID}`,
    // maxRecords: 100,
    // viewName: 'aviw_Complete_Activities',
    // allowUpdate: false,
    // allowInsert: false,
    // allowDelete: false,
    // disableLayouts: true,
    // fields: [
        // { name: 'ID', },
        // { name: 'TypeAndName', 
          // sortOrder: 1,
          // sortDirection: "asc" 
          // },
        // { name: 'Completed', },
        // { name: 'OrgUnit', },
        // { name: 'OrgUnitIdPath', }
    // ],
    // whereClause: "Deleted IS NULL"
// });

function addActivity(pRow) {
    dsActivities.createNew({
        SJA_ID: props.sjaID,
        Activity_ID: pRow.ID
    });
}

onMounted(() => {
    dsActivities.load();
    // dsActivitiesLkp.load();
})

function createNewActivity() {
    createActivityModal.value?.show();
}

</script>

<template>
    <div v-if="!props.readOnly" class="p-0 btn btn-link" :title="$t('Link scope items to the SJA')" data-bs-toggle="dropdown"
        role="button">
        <div class="d-flex align-items-center">
            <h5 class="m-0">{{ $t('Activities and Workflows') }}</h5>
            <i class="mx-1 bi bi-plus-circle-fill fs-5"></i>
        </div>
    </div>

    <ul class="dropdown-menu" aria-labelledby="activitites-dropdown">
        <!-- <a class="dropdown-item" type="button" @click="createNewActivity()">{{ $t('Create New Scope Items') }}</a> -->
        <!-- <div class="ms-3 pt-2 pb-2"><small>{{ $t('Or') }}...</small></div> -->
        <div class="ms-3 pt-2 pe-2">
        <label for="activities-lookup" class="form-label">{{ $t('Add existing activity or workflow') }}</label>
            <!-- <ODataLookup id="activities-lookup" class="form-select" :data-object="dsActivitiesLkp" -->
                <!-- :bind="sel => { addActivity(sel) }" :noClear="true" :loadRecents="false" contextField="OrgUnitIdPath"> -->
                <!-- <OColumn :headerName="$t('ID')" field="ID" width="80"></OColumn> -->
                <!-- <OColumn :headerName="$t('Type and Name')" field="TypeAndName" width="300"></OColumn> -->
                <!-- <OColumn :headerName="$t('Description')" field="Description" width="300"></OColumn> -->
                <!-- <OColumn :headerName="$t('OrgUnit')" field="OrgUnit" width="180"></OColumn> -->
            <!-- </ODataLookup> -->
        <OScopeItemsLookup class="form-select" textInput :bind="sel => { addActivity(sel) }" idPath="OrgUnitIdPath"/>
        </div>
    </ul>

    <div v-if="props.readOnly" class="d-flex">
        <h5>{{ $t('Activities and Workflows') }}</h5>
    </div>

    <hr class="m-0" />

    <div class="d-flex flex-wrap mt-2">
        <div v-for="(activity, index) in dsActivities.data" :key="index">
            <div class="d-flex me-4 align-items-center" v-if="!activity.isNewRecord" :style="isMobile ? 'width: 70%;' : 'width: 420px;'">
                <a class="text-truncate me-1" :class="{ 'strike-out': activity.Completed }" target="_blank"
                    :href="activity.Link + activity.Activity_ID">
                    <span>{{ activity.ActivityType }}</span>: <span>{{ activity.Activity }}</span>
                </a>
                <div class="ms-auto text-muted text-nowrap">({{ activity.OrgUnit }})</div>
                <OActionDelete confirm v-if="!props.readOnly" :dataObject="dsActivities" :row="activity" class="btn btn-sm btn-link p-0" :title="$t('Delete activity')" style="-bs-btn-line-height: 1;">
                                <i class="bi bi-x-circle ms-2" :class="{ 'fs-5' : isMobile}"></i>
                </OActionDelete>
            </div>
        </div>
    </div>

    <NewActivityModal :DataObjectToReload="dsActivities" :sjaID="props.sjaID" ref="createActivityModal" />
</template>